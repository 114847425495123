

function recallSort(){
    // Sorting Portfolio JS

    // alert("111")

    try {
        var mixer = mixitup('#container', {
            controls: {
                toggleDefault: 'none'
            }
        });
    } catch (err) {}
}
